import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { RankingComponent } from './ranking.component';
import { RknContComponent } from './rkn-cont/rkn-cont.component';
import { RankingGeneralComponent } from './ranking-general/ranking-general.component';
import { RankingGroupComponent } from './ranking-group/ranking-group.component';

const routes: Routes = [
    {
        path: 'ranking', component: RknContComponent,
        children: [
            { path: 'general', component: RankingGeneralComponent },
            {path: 'group', component: RankingGroupComponent}
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class RankingRoutingModule { }