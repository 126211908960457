import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input() type: string;
  @Input() message: string;

  @Input() msg: string=null;
  @Input() typeMsg: string;
  @Input() show: boolean;

  msg$ = new EventEmitter();
  typeMsg$ = new EventEmitter();
  showMsg$ = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    window.scroll(0,0);
  }

  close(){
    this.msg=null;
    this.typeMsg=null;
    this.msg$.emit(null);
    this.typeMsg$.emit(null);
  }

}
