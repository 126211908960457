import { Component, OnInit } from '@angular/core';

import { RankingService } from 'src/app/services/ranking.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-ranking-general',
  templateUrl: './ranking-general.component.html',
  styleUrls: ['./ranking-general.component.css']
})
export class RankingGeneralComponent implements OnInit {

  countries = [];
  public baseUrl: string;

  constructor(private rankingService: RankingService) { 
    this.baseUrl = environment.url_server + 'storage/uploads/';
  }

  ngOnInit() {
    this.rankingService.getCountries().subscribe(data => {
      // this.countries = data.countries
      let response = data.countries

      const totalNumOfItems = this.countries.length
      const itemPerPage = 20
      let numOfPages = totalNumOfItems / itemPerPage
      let pageNumber = 2;

      const itemToSkip = (pageNumber -1) * itemPerPage
      const items = response.slice(itemToSkip, itemPerPage + itemToSkip)
      this.countries = items
      console.log(this.countries)
    })

    
  }

  
}
