import { Component, OnInit } from '@angular/core';
import { MatchesService } from 'src/app/services/matches.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-matches-list',
  templateUrl: './matches-list.component.html',
  styleUrls: ['./matches-list.component.css']
})
export class MatchesListComponent implements OnInit {

  matches = [];
  public baseUrl: string;

  constructor(private matchesService: MatchesService) {
    this.baseUrl = environment.url_server + 'storage/uploads/';
   }

  ngOnInit() {
    this.matchesService.getAll().subscribe(data => {
      this.matches = data.data;
      console.log(data);
    });
  }

}
