import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { PoolRoutingModule } from './pool/pool-routing.module';
import { FootballRoutingModule } from './football/football-routing.module';
import { MenuRoutingModule } from './menu/menu-routing.module';
import { RankingRoutingModule } from './ranking/ranking-routing.module';

const routes: Routes = [];

@NgModule({
  imports: [
    AuthRoutingModule,
    PoolRoutingModule,
    FootballRoutingModule,
    MenuRoutingModule,
    RankingRoutingModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
