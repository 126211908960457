import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import { ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

interface HtlmInputEvent extends Event{
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user = null
  file: File;
  photoSelected: string | ArrayBuffer;
  capturedFiled = null;
  avatar;
  form: any = {
    name: '',
    last_name: '',
    email: '',
    phone: '',
    language: '',
    image: null
  }
  message = '';
  updateSuccesful = false;
  updateError= false;
  errorMessage = '';
  createError = false;

  files: any = [];
  public baseurl: string;

  constructor(
    private token: TokenStorageService, 
    private userService: UsersService,
    private cdref: ChangeDetectorRef,
    private router: Router, 
    private sanitizer: DomSanitizer
    ) { 
    this.baseurl = environment.url_server;
  }

  ngOnInit() {
    this.getUser()
    this.message = ''

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getUser(){
    
    this.userService.getUser().subscribe(data => {
      this.user = data
      this.avatar = this.baseurl + this.user.avatar
    })
  }
  
  async updateUser(){
    
    const data = {
      id: this.user.id,
      name: this.user.name,
      email: this.user.email,
      last_name: this.user.last_name,
      phone_1: this.user.phone_1,
      language: this.user.language
    }

    console.log(data)
    const USER_KEY = 'auth-user';
    let session = JSON.parse(sessionStorage.getItem(USER_KEY))
    const requestFormData = new FormData();
      
      if(this.capturedFiled != null){
        requestFormData.append('avatar', this.capturedFiled != null 
          ? this.capturedFiled : '', 
          this.capturedFiled != null 
          ? this.capturedFiled.name : ''
        )
      }
      else {
        const file = await this.showFile(this.avatar);
        requestFormData.append('avatar', file);
      }

      for ( var key in data )
        requestFormData.append(key, data[key])


    this.userService.updateUser(this.user.id, requestFormData)
      .subscribe(
        response => {
          this.updateSuccesful = true
          this.updateError = false
          console.log(response)
          this.message = "User updated successfully!"
    
          session = response
          sessionStorage.setItem(USER_KEY, JSON.stringify(session))
    
          this.router.navigateByUrl('/pools')
        }
      )
    
  }
  
  showFile(event: HtlmInputEvent): any {
    if(event.target.files && event.target.files[0]){
      this.capturedFiled = event.target.files[0];
      this.base64(this.capturedFiled).then((image: any) => {
          this.avatar = image.base;
          console.log(image); 
      })
      this.files.push(this.capturedFiled)
    }
  }

  base64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
    } catch (e) {
      return null;
    }
  })

}
