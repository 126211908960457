import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Components */
import { PoolComponent } from './pool.component';
import { CreateComponent } from './create/create.component';
import { IndexComponent } from './index/index.component';
import { ShowComponent } from './show/show.component';
import { EditComponent } from './edit/edit.component';

const routes: Routes = [
  { 
    path: 'pools', component: PoolComponent,
      children: [
        {path: '', component: IndexComponent},
        {path: 'show/:id', component: ShowComponent},
        {path: 'create', component: CreateComponent},
        {path: 'edit/:id', component: EditComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class PoolRoutingModule { }
