import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class RankingService {
  public baseUrl: string;
  headers = new Headers();

  constructor(private http: HttpClient) {
    this.baseUrl = environment.url_server;
  }

  getCountries(): Observable<any> {
    return this.http.get(`${environment.url_server}api/countries`)
  }

  getTeamsByGroup(groupId):Observable<any> {
    return this.http.get(`${this.baseUrl}api-football/teams/group/${groupId}`)
  }

}
