import { Component } from '@angular/core';

@Component({
  selector: 'pool-app',
  template: `<router-outlet></router-outlet>`,
})

export class PoolComponent {
  constructor() {}
}
