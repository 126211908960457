import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadService {

  public loader = false;

  showLoad() {
    this.loader = true;
  }

  hideLoad() {
    this.loader = false;
  }

  hideSlow(t: number = 3000) { 
    setTimeout(() => {
      this.hideLoad()
    }, t);
  }

}
