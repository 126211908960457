import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.css']
})
export class TranslateComponent implements OnInit {
  href: string = "";

  constructor(private translateService: TranslateService, private router: Router) { }

  ngOnInit() {
    this.href = this.router.url;
  }

  selectLanguage(lang: string) {
    this.translateService.use(lang);
    localStorage.setItem('lang', lang);
    this.href = this.router.url;

    if(this.href == '/register') {
      this.setCaptchaLang(document.getElementById("recaptcha-container"), lang);      
    }

  }
  
  setCaptchaLang(recaptchaContainer, lang) {
    lang = lang || "en";

    // 1. Search for the ReCaptcha iframe
    const iframeGoogleCaptcha = recaptchaContainer.querySelector('iframe');

    // 2. Retrieve the current language
    const currentLang = iframeGoogleCaptcha.getAttribute("src").match(/hl=(.*?)&/).pop();

    // 3. Verify if the language that you want to set is different to the current one
    if (currentLang !== lang) {
        // 4. If it is, change it
        iframeGoogleCaptcha.setAttribute(
            "src",
            iframeGoogleCaptcha.getAttribute("src").replace(
                /hl=(.*?)&/,
                'hl=' + lang + '&'
            )
        );
    }
  }

}
