import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  form: any = {};
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
  hide : boolean = true;
  recapcha : string;
  ipAddress:string;
  public_key_recaptcha : string;
  recaptchaCheck:boolean = false;
  errorRecaptchaCheck : boolean = false;

  constructor(private authService: AuthService, private router: Router,  private translate: TranslateService,) { 
    this.public_key_recaptcha = environment.public_key_recaptcha;
    
  }
  
  ngOnInit() {
    this.getIP();  
  }

  getIP() {  
    this.authService.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
    });  
  }  

  onSubmit() {
    const password = this.form.password;
    const confirmPassword = this.form.conPassword;

    if (password === confirmPassword) {
      
      if(!this.recaptchaCheck){
        this.errorRecaptchaCheck = true;        
        this.isSignUpFailed = true;
      } else {
        this.form.token = this.recapcha;
        this.form.remoteip = this.ipAddress;

        this.authService.register(this.form).subscribe(
          data => {
            console.log(data);
            this.isSuccessful = true;
            this.errorRecaptchaCheck = false; 
            this.isSignUpFailed = false;
            this.authService.setMessage(data.message_return.ERROR_MENSAGGE);
            this.router.navigateByUrl('/login');
          },
          err => {
          
            console.log( err.error.message_return.ERROR_MENSAGGE);
            this.errorMessage = err.error.message || err.error.message_return.ERROR_MENSAGGE || err.error.ERROR.ERROR_MENSAGGE || err;
            this.isSignUpFailed = true;
            this.errorRecaptchaCheck = false;
          }
        );
      }
    }

    
  }

  hidePassword() {
    this.hide = !this.hide;
  }

  resolved(captchaResponse: string) {
    this.recapcha = captchaResponse;
    this.recaptchaCheck = captchaResponse != null ? true : false;

  }


}
