import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class GamesRegisterService {

  public baseUrl: string;
  public year;
  public userId;
  headers = new Headers();

  constructor(private http: HttpClient, private token: TokenStorageService) {
    this.baseUrl = environment.url_server + 'api-football/gamesRegister';
    this.year = environment.year;

    var user = this.token.getUser();

    this.userId = user.result.id;
  }

  getGameRegisterByPool(poolId): Observable<any> {
    return this.http.get(`${this.baseUrl}?user_id=${this.userId}&pool_id=${poolId}`)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  createGameRegister(data): Observable<any> {
    return this.http.post(`${this.baseUrl}`, data)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  updateGameRegister(id, data): Observable<any> {
    return this.http.put(`${this.baseUrl}/${id}`, data)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  updateOrCreate(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/multiple`, data)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error;
    }
    return throwError(errorMessage);
  }
}
