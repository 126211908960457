import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { ProfileComponent } from './profile/profile.component';
import { RulesComponent } from './rules/rules.component';

const routes: Routes = [
    {
        path: 'profile', component: ProfileComponent
    },
    {
        path: 'rules', component: RulesComponent
    }
]



@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class MenuRoutingModule { }
  