import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatchesRoutingModule } from './matches-routing.module';
import { PoolModule } from '../pool/pool.module';

/* Components */
import { MatchesComponent } from './matches.component';
import { MatchesListComponent } from './matches-list/matches-list.component';
import { MatchDetailComponent } from './matches-details/match-detail/match-detail.component';
import { LiveComponent } from './matches-details/live/live.component';
import { DetailComponent } from './matches-details/detail/detail.component';
import { StatisticsComponent } from './matches-details/statistics/statistics.component';


@NgModule({
  declarations: [
    MatchesComponent,
    MatchesListComponent,
    LiveComponent,
    MatchDetailComponent,
    DetailComponent,
    StatisticsComponent
  ],
  imports: [
    CommonModule,
    MatchesRoutingModule,
    PoolModule
  ]
})
export class MatchesModule { }
