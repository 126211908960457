import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuRoutingModule } from './menu-routing.module';
import { PoolModule } from '../pool/pool.module';

import { MenuComponent } from './menu.component';
import { ProfileComponent } from './profile/profile.component';
import { RulesComponent } from './rules/rules.component'



@NgModule({
  declarations: [
    MenuComponent, 
    ProfileComponent, 
    RulesComponent
  ],
  imports: [
    CommonModule,
    MenuRoutingModule,
    PoolModule
  ]
})
export class MenuModule { }
