import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

export interface User{
  email: string,
  token: string
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  hide : boolean = true;
  form: any = {};
  successful = false;
  password = '';
  passwordConfirm = '';
  failed = false;
  token: string;
  user: User;
  errorMessage = '';

  constructor(private auth: AuthService, private router: Router) {
    let url = this.router.parseUrl(this.router.url);
    this.token = url.queryParams['token'];
   }

  ngOnInit(): void {
    this.auth.findtoken(this.token).subscribe(data => {
      if(data.message_return.ERROR == false){
        this.user = data.result;
      }
     console.log(this.user)
    },err => {
      this.router.navigateByUrl("forgot-password");
    });
  }

  updatePassword() {
    const password = this.form.password;
    const passwordConfirm = this.form.passwordConfirm;

    if (password === passwordConfirm){
      this.form.token = this.token;
      this.form.email = this.user.email;
      console.log(this.form);
      this.auth.resetPassword(this.form).subscribe(data => {
          console.log(data);
          this.successful = true;
          this.failed = false;
          this.auth.setMessage(data.message_return.ERROR_MENSAGGE);
          this.router.navigateByUrl('/login');
        }
      );
    }
  }

  hidePassword() {
    this.hide = !this.hide;
  }
  
}
