import { Component, OnInit } from '@angular/core';
import { PoolService } from './../../services/pool.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  pools = [];

  constructor(private poolservice: PoolService) {
    
  }

  ngOnInit() {
    this.poolservice.getAll().subscribe(data => {
      this.pools = data.data;
      // console.log(this.pools);
    });
  }

}

