import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

/* Modules */
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RecaptchaModule } from 'ng-recaptcha';


/* Components */
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { TranslateComponent } from './../components/translate/translate.component';
import { MessageComponent } from './../components/message/message.component';

export const language = (localStorage.getItem('lang') == null) ? "en" : localStorage.getItem('lang');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');

}

@NgModule({
  declarations: [
    TranslateComponent,
    MessageComponent,
    AuthComponent,
    RegisterComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent
  ],
  imports: [
    BrowserModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    RecaptchaModule
  ],
  exports:[
    BrowserModule,
    TranslateComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    HttpClientModule,
    RecaptchaModule,
    MessageComponent

  ],
  bootstrap: [AuthComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class AuthModule { }

