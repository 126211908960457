import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PoolService } from 'src/app/services/pool.service';
import { CurrenciesService } from './../../services/currencies.service';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ReadVarExpr } from '@angular/compiler';

interface HtlmInputEvent extends Event{
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  
  file: File;
  photoSelected: string | ArrayBuffer;
  hide : boolean = true;
  currentPool = null;
  message = '';
  form: any =  {
    name: '',
    description: '',
    image: null,
    is_private: false,
    price: '',
    currency_id: '',
    user_id: '',
    password: null, 
    _method : null 
  }
  updateSuccesful = false;
  updateError= false;
  capturedFiled = null;
  errorMessage = '';
  createError = false;
  currencies = [];
  poolId
  image;

  public baseurl: string;

  constructor(
    private poolService: PoolService, 
    private currenciesService: CurrenciesService,
    private route: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private router: Router, 
  ) { 
    this.baseurl = environment.url_server;
  }

  ngOnInit() {
    this.message = '';
    
    this.poolId = this.route.snapshot.paramMap.get('id');

    this.getPool(this.poolId);

    this.currenciesService.getAllCurrencies().subscribe(data => {
      this.currencies = data.currency;
    });
      
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getPool(id){

    this.poolService.get(id)
      .subscribe( response => {
        this.currentPool = response.data;
        this.image = this.baseurl + 'storage/' + this.currentPool.image
        console.log(response);
      }, error => {
        console.log(error);
      }
    )
  }

  async  getFileFromUrl(url, name, defaultType = 'image/jpeg'){
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }

  async updatePool(){

    const data = {
      pool_id: this.poolId,
      name: this.currentPool.name,
      price: this.currentPool.price,
      description: this.currentPool.description,
      is_private : this.currentPool.is_private ? 1 : 0,
      currency_id: this.currentPool.currency_id,
      _method: "PUT"
    };

    const password = this.currentPool.password;
    
    if(this.currentPool.is_private==1){
      Object.assign(data, {"password":password});
    }
      
      const requestFormData = new FormData();
      
      if(this.capturedFiled != null)
        requestFormData.append('image', this.capturedFiled != null ? this.capturedFiled : '', this.capturedFiled != null ? this.capturedFiled.name : '')
      else {
        const file = await this.getFileFromUrl(this.image, 'example.jpg');
        requestFormData.append('image', file);
      }

      for ( var key in data )
        requestFormData.append(key, data[key])
       
      this.poolService.update(this.currentPool.id, requestFormData)
        .subscribe(
          response => {
            this.updateSuccesful = true;
            this.updateError = false;
            console.log(response);
            this.message = "The pool was update successfully!"
            this.router.navigateByUrl('/pools/show/'+this.poolId);
          },
          err=> {
              console.log(err.error.message_return.ERROR_MENSAGGE);
              this.errorMessage = err.error.message || err.error.message_return.ERROR_MENSAGGE || err.error.ERROR.ERROR_MENSAGGE || err;
              this.createError = true;
          
          });

  }

  showFile (event: HtlmInputEvent): any {
    if(event.target.files && event.target.files[0]){
      this.capturedFiled = event.target.files[0];
      this.file = <File>event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.photoSelected = reader.result;
      reader.readAsDataURL(this.file);
    }
  }
 
  showInput(ischecked){
    var element = document.getElementById("password");
    (ischecked.checked) ? element.style.display='block' : element.style.display='none';
  }
  
  hidePassword() {
    this.hide = !this.hide;
  }
}
