import { Component, OnInit } from '@angular/core';
import { GroupsService } from 'src/app/services/groups.service';
import { RankingService } from 'src/app/services/ranking.service';

@Component({
  selector: 'app-ranking-group',
  templateUrl: './ranking-group.component.html',
  styleUrls: ['./ranking-group.component.css']
})
export class RankingGroupComponent implements OnInit {
  groups = [];
  teams = [];
  season = []

  constructor(private groupService: GroupsService, private rknService: RankingService) { }

  ngOnInit() {
    this.groupService.getAll().subscribe(data => {
      this.groups = data.data
        this.groups.forEach(group => {
          this.rknService.getTeamsByGroup(group.id).subscribe(data => {
            this.season[data.Group] = data.Teams
            this.teams[group.name] = this.season[data.Group].filter(Team => Team.football_season_id == 2)
          });
        });
    })
  }

}
