import { Component } from '@angular/core';

@Component({
  selector: 'football-app',
  template: `<router-outlet></router-outlet>`,
})

export class FootballComponent {
  constructor() {}
}
