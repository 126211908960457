import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { authInterceptorProviders } from './helpers/auth.interceptor';
import { LoadComponent } from './auth/load/load.component';
import { RecaptchaModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';

export const language = (localStorage.getItem('lang') == null) ? "en" : localStorage.getItem('lang');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { AuthModule } from './auth/auth.module';
import { PoolModule } from './pool/pool.module';
import { FootballModule } from './football/football.module';
import { MatchesModule } from './matches/matches.module';
import { MenuModule } from './menu/menu.module';
import { RankingModule } from './ranking/ranking.module';

declare var $: any;
@NgModule({
  declarations: [
    AppComponent,
    LoadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NoopAnimationsModule,
    RecaptchaModule,
    AuthModule,
    PoolModule,
    FootballModule,
    MatchesModule,
    MenuModule,
    RankingModule
  ],
  providers: [
    authInterceptorProviders,
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: language, // use En language
    }],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
