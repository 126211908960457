import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PoolService } from 'src/app/services/pool.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {
  
  currentPool = null;
  users = [];
  poolId = null;
  userId = null;
  associated = null;
  public baseurl: string;
  image: any;

  constructor(private poolservice: PoolService, private route: ActivatedRoute, private token: TokenStorageService) {
    this.poolId  = this.route.snapshot.paramMap.get('id');
    this.userId = this.token.getUser();
    this.baseurl = environment.url_server + 'storage/';
  }

  ngOnInit(): void {
    this.getPool(this.poolId);
  }

  getPool(id) {
    this.poolservice.get(id).subscribe(data => {
      this.currentPool = data; 
      this.image = this.baseurl + this.currentPool.data.image;
      this.users = this.currentPool.data.users;
      
      const result = this.users.filter(user => user.user.id===this.userId.result.id);
      
      if (result.length == 1) {
        this.associated = true
      } else {
        this.associated = false
      } 
    })

  }

  updatePayment(id) {
    this.poolservice.updatePayment(id).subscribe(data => {
      window.location.reload();    
    });

  }

  join() {
    const data = { user_id:  this.userId.result.id, football_pool_id: this.currentPool.data.id }
    this.poolservice.associateUser(data).subscribe(data => {
      window.location.reload();
    });
  }
}
