import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MatchesService {

  public baseUrl: string;
  public year;

  constructor(private http: HttpClient) { 
    this.year = environment.year;
    this.baseUrl  = environment.url_server + 'api-football'; 
  }

  getAll(): Observable<any> {
    return this.http.get(`${this.baseUrl}/gamesDate?year=${this.year}`)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  getbyId(matchId): Observable<any> {
    return this.http.get(`${this.baseUrl}/games/${matchId}`)
  }


  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message; // Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }

}
