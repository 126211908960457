import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GamesService {
 
  public baseUrl: string;
  public year;
  headers = new Headers();

  constructor(private http: HttpClient) {
    this.baseUrl = environment.url_server + 'api-football';
    this.year = environment.year;
  }

  getGameByGroup(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/games/group/${id}?year=${this.year}`)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error;
    }
    return throwError(errorMessage);
  } 
}
