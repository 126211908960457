import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false; 
  errorMessage = '';
  hide : boolean = true;
  msg : string ='';
  typeMsg : string;

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private router: Router) { }

  ngOnInit() {
    if( this.authService.getMessage() ){
      this.typeMsg = "success";
      this.msg = this.authService.getMessage();
    }

    if (this.tokenStorage.getToken()) {
      this.router.navigateByUrl('/pools');
    }
  }

  onSubmit() {
    this.authService.login(this.form).subscribe(
      data => {
        if ( data.message_return && !data.message_return.ERROR ) {
          this.tokenStorage.saveToken(data.result.access_token);
          this.tokenStorage.saveUser(data);
          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.router.navigateByUrl('/pools');
        }
      },
      err => {
        this.errorMessage = err.error.message || err.error.ERROR_MENSAGGE;
        this.isLoginFailed = true;
      }
    );
  }

  hidePassword() {
    this.hide = !this.hide;
  }
}

