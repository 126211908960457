import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GamesRegisterService } from 'src/app/services/games-register.service';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {

  poolId = null;
  gamesScores: string = '';

  constructor(private gameRegisterService: GamesRegisterService, private route: ActivatedRoute) {
    this.poolId = this.route.snapshot.paramMap.get('poolId');
  }

  ngOnInit(): void {
    this.gameRegisterService.getGameRegisterByPool(this.poolId).subscribe(data => {
      this.gamesScores = JSON.stringify(data.data);
    })
  }

}
