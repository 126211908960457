import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

/* Modules */
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';

/* Components */
import { PoolComponent } from './pool.component';
import { PoolRoutingModule } from './pool-routing.module';
import { CreateComponent } from './create/create.component';
import { IndexComponent } from './index/index.component';
import { ShowComponent } from './show/show.component';
import { EditComponent } from './edit/edit.component';
import { HeaderComponent } from './../components/header/header.component';
import { FooterComponent } from './../components/footer/footer.component';
import { AuthModule } from './../auth/auth.module';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    PoolComponent,
    CreateComponent,
    IndexComponent,
    ShowComponent,
    EditComponent,
  ],
  imports: [
    AuthModule,
    PoolRoutingModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatExpansionModule,
  ],
  exports: [
    AuthModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatExpansionModule,
    HeaderComponent,
    FooterComponent,
  ],
  bootstrap: [PoolComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class PoolModule {}

