import { Component, OnInit, Input, OnChanges, AfterViewInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { GamesRegisterService } from 'src/app/services/games-register.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.css']
})
export class MatchesComponent implements OnInit, OnChanges, AfterViewInit {
  [x: string]: any;
  form: FormGroup
  @Input() games : [];
  @Input() gamesScores : string;
  @Output() inputFull : EventEmitter<any> = new EventEmitter();
  public baseurl: string;
  public scores: [];

  createOrUpdateSuccesful = false;
  createOrUpdateError = false;
  errorMessage = '';
  user_id = null;
  poolId = null;
 
  constructor(private route: ActivatedRoute, private token: TokenStorageService, private gamesRegiterS: GamesRegisterService) { 
    this.baseurl = environment.url_server + 'storage/uploads/';
    this.poolId = this.route.snapshot.paramMap.get('poolId');
  }

  ngOnChanges(changes: SimpleChanges) {
    if(typeof changes.games.currentValue !== 'undefined'){
      this.scores = JSON.parse(this.gamesScores);
      this.scores.forEach(score => {
        var list = this.scores.filter((t) => t['football_game_id'] === score['football_game_id'])
        
        for (const [key, value] of Object.entries(this.games)) {  
          if (value['id'] === score['football_game_id']) {
            this.games[key]['goals_team_a'] = list[0]['goals_team_a'];
            this.games[key]['goals_team_b'] = list[0]['goals_team_b'];
            this.inputFull.emit(score['game']['football_group_id']);
            this.games[key]['full_bet'] = 'scoreFull';
            this.games[key]['full_bet_2'] = 'scoreFull2';
          }
        }
      }); 

      let groups = {};

      this.games.forEach( game => {
        groups['goals_team_a_'+game['id']] = new FormControl(game['goals_team_a'], [Validators.required]);
        groups['goals_team_b_'+game['id']] = new FormControl(game['goals_team_b'], [Validators.required]);
      });

      this.form = new FormGroup(groups);

    }
  }

  ngOnInit() {
    this.form = new FormGroup({});
    this.user_id = this.token.getUser();
  }

  ngAfterViewInit() {}

  result(element){
    var splitId = element.target.id.split('-');
    var position = splitId[1] == 1 ? 2 : 1;
    var input = document.getElementById("score-" + position + "-" + splitId[2]) as HTMLInputElement | null;
  
    if((element.target.value.length != 0) && (input.value.length != 0)){
      document.getElementById("date-" + splitId[2]).classList.add("scoreFull");
      document.getElementById("day-" + splitId[2]).classList.add("scoreFull2");
      document.getElementById("month-" + splitId[2]).classList.add("scoreFull2");

      document.getElementById("score-" + "1-" + splitId[2]).classList.remove("border-class");
      document.getElementById("score-" + "2-" + splitId[2]).classList.remove("border-class");
      document.getElementById("required_goals_team_a_" + splitId[2]).style.display='none';
      document.getElementById("required_goals_team_b_" + splitId[2]).style.display='none';

      var game = this.games.filter((t) => t['id'] === parseInt(splitId[2]))
      this.inputFull.emit(game[0]['football_group_id']);

    }else{
      document.getElementById("date-" + splitId[2]).classList.remove("scoreFull");
      document.getElementById("day-" + splitId[2]).classList.remove("scoreFull2");
      document.getElementById("month-" + splitId[2]).classList.remove("scoreFull2");

      if(input.value.length != 0){
        document.getElementById("required_goals_team_a_" + splitId[2]).style.display='block';
        document.getElementById("required_goals_team_b_" + splitId[2]).style.display='block';
      }
    }
  }

  createOrUpdate(){

    var valid = true;

    Object.keys(this.form.controls).forEach(form => {
      const controlErrors: ValidationErrors = this.form.get(form).errors;
      if (controlErrors != null) {
        var classButtons = Array.from(document.getElementsByClassName(String(form)) as HTMLCollectionOf<any>)
        classButtons.forEach(classButton => {
          classButton.classList.add("border-class")
        });
        valid = false
      }
    });
    if (!valid) {
      return
    }

    const userId = this.user_id.result.id;
    const poolId = this.poolId;
    const games =  [];

    for (let i = 0; i < this.games.length; i++) {
      games.push({
        user_id: userId,
        football_pool_id: poolId,
        goals_team_a: this.form.value[`goals_team_a_${this.games[i]['id']}`],
        goals_team_b: this.form.value[`goals_team_b_${this.games[i]['id']}`],
        football_game_id: this.games[i]['id']});
    }

    this.gamesRegiterS.updateOrCreate({games}).subscribe(data=> {
      console.log(data);
      this.createOrUpdateSuccesful = true;
      this.createOrUpdateError = false;
    })

  }
}

