import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rkn-cont',
  templateUrl: './rkn-cont.component.html',
  styleUrls: ['./rkn-cont.component.css']
})
export class RknContComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
