import { Component, Input, OnInit } from '@angular/core';
import { GroupsService } from 'src/app/services/groups.service';
import { GamesService } from 'src/app/services/games.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { GamesRegisterService } from 'src/app/services/games-register.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {
  @Input() gamesScores : string;
  groups = [];
  games = [];
  baseUrl : string;
  user_id = null;
  poolId = null;
  createSuccesful = false;
  createError = false;
  countGroups: Array<number> = [0,0,0,0,0,0,0,0]
  
  constructor(private groupService: GroupsService, private gamesRegisterService: GamesRegisterService, private token: TokenStorageService, private gameService: GamesService, private route: ActivatedRoute) {
    this.baseUrl =  environment.url_server + 'storage/';
    this.poolId = this.route.snapshot.paramMap.get('poolId');
  }
  
  ngOnInit() {
    this.groupService.getAll().subscribe(data => {
     this.groups = data.data;
      this.groups.forEach(group => {
        this.gameService.getGameByGroup(group.id).subscribe(data => {      
          this.games[data.Group] = data.Games
        });
      });
    });
    this.user_id = this.token.getUser();
  }

  receiveInputData(group) {
    this.countGroups[group-1]++;
    const gamesByGroup = 6;
    
    var percentDiv = document.getElementById('percent-'+group);
    var total = Math.trunc((100 * this.countGroups[group-1]) / gamesByGroup); 
    percentDiv.textContent = total + "%";
  }
}