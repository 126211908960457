import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { LoadService } from './services/load.service';
import { TranslateService } from '@ngx-translate/core';
import { TokenStorageService } from './services/token-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  loader = false;
  selectedLanguage = 'en';
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username: string;

  constructor(
    private router: Router, 
    public loadService: LoadService, 
    private translateService: TranslateService, 
    private tokenStorageService: TokenStorageService) { 

    var lang = (localStorage.getItem('lang') == null) ? this.selectedLanguage : localStorage.getItem('lang');

    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);

    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          if(event.url === '/login')
            this.loadService.showLoad();
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError) {
          this.loadService.hideSlow();
        }
      }
    )
  }

  selectLanguage(lang: string) {
    this.isLoggedIn = !!this.tokenStorageService.getToken();  
  }

  logout() {
    this.tokenStorageService.signOut();
    window.location.reload();
  }
}
