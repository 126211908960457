import { Component, OnInit } from '@angular/core';
import { PoolService } from './../../services/pool.service';
import { CurrenciesService } from './../../services/currencies.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {

  form: any = {
    name: '',
    description: '',
    image: null,
    is_private: false,
    price: '',
    currency_id: '',
    user_id: '',
    password: null
  }; 
  capturedFiled = null;
  user_id = null;
  currencies = [];
  createSuccesful = false;
  createError = false;
  errorMessage = '';
  public files: any = [];
  public preview = "/assets/images/add_photo_alternate_FILL1_wght400_GRAD0_opsz48.svg";

  constructor(
    private poolService: PoolService, 
    private currenciesService: CurrenciesService,
    private token: TokenStorageService, 
    private router: Router, 
    private sanitizer: DomSanitizer,
    private cdref: ChangeDetectorRef) 
  { }

  ngOnInit() {
    this.user_id = this.token.getUser();

    this.currenciesService.getAllCurrencies().subscribe(data => {
      this.currencies = data.currency;
      // console.log('DATA:'+JSON.stringify(data.currency));
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  showInput(ischecked){

    var element = document.getElementById("password");
    (ischecked.checked) ? element.style.display='block' : element.style.display='none';

  }

  onSubmit(){    
    const userId = this.user_id.result.id;
    const password = this.form.password
    const data ={
        "name": this.form.name,
        "description": this.form.description,
        "is_private": this.form.is_private ? 1 : 0,
        "price": this.form.price,
        "currency_id": this.form.currency_id,
        "user_id": userId
       
      };

    if(this.form.is_private==1)
      Object.assign(data, {"password":password});

      const requestFormData = new FormData();
      if(this.capturedFiled != null)
      requestFormData.append('image', this.capturedFiled != null ? this.capturedFiled : '', this.capturedFiled != null ? this.capturedFiled.name : '')
      
      for ( var key in data ) {
       // console.log(key, data[key])
        requestFormData.append(key, data[key])
      }
    this.poolService.create(requestFormData).subscribe(
      data => {
        //console.log(data);
        this.createSuccesful = true;
        this.createError = false;
        this.router.navigateByUrl('/pools');
      },
      err => {
        console.log(err.error.message_return.ERROR_MENSAGGE);
        this.errorMessage = err.error.message || err.error.message_return.ERROR_MENSAGGE || err.error.ERROR.ERROR_MENSAGGE || err;
        this.createError = true;
      });
  }

  showFile(event): any {
    var element = document.getElementById("img-source");

    this.capturedFiled = event.target.files[0];
    this.base64(this.capturedFiled).then((image: any) => {
      this.preview = image.base;
    //  console.log(image); 
    })
    this.files.push(this.capturedFiled);

    element.style.width='150px';
    element.style.borderRadius='50%';
  }

  base64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
    } catch (e) {
      return null;
    }
  })
}
