import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { TokenStorageService } from '../services/token-storage.service'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public baseurl: string;
  public message;
  temp: string;
  token;
  isAuthenticated;
  authStatusListener;
  
  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) {
    this.baseurl = environment.url_server;
  }

  login(credentials): Observable<any> {
    return this.http.post(this.baseurl + 'api/auth/login', {
      email: credentials.email,
      password: credentials.password,
      remember_me: true,
      language: localStorage.getItem('lang')
    }, httpOptions);
  }

  register(user): Observable<any> {
    return this.http.post(this.baseurl + 'api/auth/signup', {
      name: user.name,
      last_name: user.lastname,
      email: user.email,
      password: user.password,
      password_confirmation: user.conPassword,
      rol_id: 1,
      token: user.token,
      language: (localStorage.getItem('lang') == null) ? 'en' : localStorage.getItem('lang'),
      remoteip: user.remoteip
    }, httpOptions);
  }

  forgotPassword(credentials): Observable<any> {
    return this.http.post(this.baseurl + 'api/password/create', {
      email: credentials.email,
      lang: (localStorage.getItem('lang') == null) ? 'en' : localStorage.getItem('lang'),
      is_pool: true
    }, httpOptions);  
  }

  resetPassword(user): Observable<any> {
    return this.http.post(this.baseurl + 'api/password/reset', {
      email: user.email,
      password: user.password,
      password_confirmation: user.passwordConfirm,
      token: user.token,
      lang: (localStorage.getItem('lang') == null) ? 'en' : localStorage.getItem('lang'),
      is_pool: true
    }, httpOptions);
  }

  findtoken(token): Observable<any> {
    this.temp = this.baseurl + 'api/password/find/' + token;
    return this.http.get( this.temp,token)
  }

  logout() {
    this.tokenStorage.signOut();
    return this.http.get(this.baseurl + 'api/auth/logout');    
  }

  getMessage(){
    return this.message;
  }

  setMessage(msg){
    this.message = msg;
  }

  public getIPAddress() {
    return this.http.get("https://api.ipify.org/?format=json");
  }

}
