import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  form: any = {};
  isSuccessful = false;
  isFailed = false;
  errorMessage = '';
  msg:string = '';
  typeMsg:string;

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {}

  sendMail() {
    this.auth.forgotPassword(this.form).subscribe( data => {
      console.log(data);
      this.isSuccessful = true;
      this.isFailed = false;
      this.typeMsg = "success";
      this.msg = data.ERROR_MENSAGGE;
    },
    err => {
      console.log(err);
      this.typeMsg = "error";
      this.msg = err.error.ERROR_MENSAGGE || err;
    });
  }

}
