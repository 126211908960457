import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Components */
import { FootballComponent } from './football.component';
import { ShowComponent } from './game-register/show/show.component';

const routes: Routes = [
  { 
    path: 'football', component: FootballComponent,
      children: [
        {path: 'games-register/:poolId', component: ShowComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class FootballRoutingModule { }
