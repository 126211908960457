import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RankingRoutingModule } from './ranking-routing.module';
import { PoolModule } from '../pool/pool.module';

import { RankingComponent } from './ranking.component';
import { RknContComponent } from './rkn-cont/rkn-cont.component';
import { RankingGeneralComponent } from './ranking-general/ranking-general.component';
import { RankingGroupComponent } from './ranking-group/ranking-group.component';
import { RknGroupsComponent } from '../components/rkn-groups/rkn-groups.component';



@NgModule({
  declarations: [
    RankingGeneralComponent, 
    RankingComponent, 
    RankingGroupComponent, 
    RknGroupsComponent,
    RknContComponent
  ],
  imports: [
    CommonModule,
    RankingRoutingModule,
    PoolModule
  ]
})
export class RankingModule { }
