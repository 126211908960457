import { Component, OnInit, AfterViewChecked, HostListener, ElementRef} from '@angular/core';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  userId = null;
  isOpen = true;
  public baseurl: string;
  avatar : any;
  noavatar = false;

  @ViewChild('mySidenav', { static: true }) mySidenav: ElementRef;

  constructor(private token: TokenStorageService, private auth: AuthService, private router: Router) { 
    this.userId = this.token.getUser();
    this.baseurl = environment.url_server;

  }

  ngOnInit() {
    this.userId = this.token.getUser();
    this.avatar = (this.userId.result.avatar) ? this.baseurl + this.userId.result.avatar :'/assets/images/account_circle_black_24dp.svg';
  }

  openNav() {
    document.getElementById('mySidenav').style.display = 'block';
    document.getElementById('mySidenav').style.width = '250px';
    this.isOpen = true;
  }

  showSidenav() {
    this.isOpen = true;
  }

  logout() {
    this.auth.logout().subscribe( data => {
      this.token = null;
    });
    return false;
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
     if (document.getElementById('mySidenav').contains(event.target)) {
        // console.log('id', event.target.id);
        if(event.target.id == 'pool') {
          console.log('Pool event');
        } 
        if(event.target.id == 'explore') {
          console.log('Explore event');
        }
        if(event.target.id == 'match') {
          console.log('Match event');
        }
        if(event.target.id == 'ranking') {
          console.log('Ranking event');
        }
        if(event.target.id == 'rules') {
          this.router.navigateByUrl('/rules');
        }
        if(event.target.id == 'notification') {
          console.log('Notification event');
        }
        if(event.target.id == 'profile') {
          this.router.navigateByUrl('/profile');
        }
        if(event.target.id == 'logoutBtn') {
          this.logout();
        }
     } else {
      document.getElementById('mySidenav').style.display = 'none';
     }
  }
}
