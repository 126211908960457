import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatchesService } from 'src/app/services/matches.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-match-detail',
  templateUrl: './match-detail.component.html',
  styleUrls: ['./match-detail.component.css']
})
export class MatchDetailComponent implements OnInit {

  matchId = null
  currentMatch = null
  public baseUrl: string;

  constructor(private route: ActivatedRoute, private matchService: MatchesService) { 
    this.matchId = this.route.snapshot.paramMap.get('id')
    this.baseUrl = environment.url_server + 'storage/uploads/'
  }

  ngOnInit() {
    console.log(this.matchId)
    this.getMatch(this.matchId)
  }

  getMatch(matchId){
    this.matchService.getbyId(matchId).subscribe( data => {
      this.currentMatch = data.Game
      console.log(this.currentMatch)
    })
  }

}
