import { Component, } from '@angular/core';

@Component({
  selector: 'menu-app',
  template: `<router-outlet></router-outlet>`
})

export class MenuComponent {
  constructor() {}
}
