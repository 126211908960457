import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {
  public baseUrl: string;
  headers = new Headers();

  constructor(private http: HttpClient) {
    this.baseUrl = environment.url_server;
  }

  getAllCurrencies(): Observable<any> {
    return this.http.get(`${environment.url_server}api/currencies`)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message; // Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }
}
