import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

/* Modules */


/* Components */
import { FootballRoutingModule } from './football-routing.module';
import { FootballComponent } from './football.component';
import { PoolModule } from './../pool/pool.module';
import { ShowComponent } from './game-register/show/show.component';
import { GroupsComponent } from './../components/groups/groups.component';
import { MatchesComponent } from './../components/matches/matches.component';

@NgModule({
  declarations: [
    FootballComponent,
    ShowComponent,
    GroupsComponent,
    MatchesComponent
  ],
  imports: [
    PoolModule,
    FootballRoutingModule
  ],
  bootstrap: [FootballComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class FootballModule {}

