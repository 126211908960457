import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Components */
import { MatchesComponent } from './matches.component';
import { MatchesListComponent } from './matches-list/matches-list.component';
import { MatchDetailComponent } from './matches-details/match-detail/match-detail.component';
import { LiveComponent } from './matches-details/live/live.component';
import { DetailComponent } from './matches-details/detail/detail.component';
import { StatisticsComponent } from './matches-details/statistics/statistics.component';

const routes: Routes = [
  {
    path: 'matches', component:  MatchesComponent,
      children: [
        {path: 'matches-list', component: MatchesListComponent}
      ]
  },
  {
    path: 'matches-detail/:id', component: MatchDetailComponent,
    children: [
      { path: 'live', component: LiveComponent },
      { path: 'detail', component: DetailComponent },
      { path: 'statistics', component: StatisticsComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MatchesRoutingModule { }
