import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PoolService {
  public baseUrl: string;
  headers = new Headers();

  constructor(private http: HttpClient) {
    this.baseUrl = environment.url_server + 'api-football/pools';
  }

  getAll(): Observable<any> {
    return this.http.get(this.baseUrl)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  get(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/${id}`)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  create(data): Observable<any> {
    return this.http.post(this.baseUrl, data)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  update(id, data): Observable<any> {
    return this.http.post(`${this.baseUrl}/${id}`, data)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }
  
  delete(id): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message; // Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }

  updatePayment(id): Observable<any> {
    return this.http.get(`${environment.url_server}api-football/updatePayment/${id}`)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  associateUser(data): Observable<any> {
    return this.http.post(`${environment.url_server}api-football/associateUser`, data)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

}
