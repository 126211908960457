import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rkn-groups',
  templateUrl: './rkn-groups.component.html',
  styleUrls: ['./rkn-groups.component.css']
})
export class RknGroupsComponent implements OnInit, OnChanges {
  @Input() teams : [];
  baseUrl: string;

  constructor() { 
    this.baseUrl = environment.url_server + 'storage/uploads/';
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(typeof changes.teams !== 'undefined'){
      this.teams
    }
  }

}
